import type { AdminApiGetClient, AdminApiGetSite } from '../types';
import { defineStore } from 'pinia';

interface State {
  currentClient: AdminApiGetClient['clients_by_pk'] | null;
  currentSite: AdminApiGetSite['sites_by_pk'] | null;
}

export const adminStore = defineStore('adminStore', {
  state: (): State => ({
    currentClient: null,
    currentSite: null,
  }),
  actions: {
    async setCurrentClient(client: State['currentClient']) {
      this.currentClient = client;
    },
    async setCurrentSite(site: State['currentSite']) {
      this.currentSite = site;
    },
  },
});
